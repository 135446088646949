import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, catchError, switchMap, filter, take } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthService,
  ) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (httpRequest.headers.get('No-Authorization')) {
      return next.handle(httpRequest);
    }
    
    return this.auth.isLoading$.pipe(
      filter((isLoading) => !isLoading),
      take(1),
      switchMap(() => this.auth.getAccessTokenSilently()),
      map((token) => httpRequest.clone({ setHeaders: { Authorization: `Bearer ${token}` } })),
      switchMap((modified) => next.handle(modified)),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401 || err.message === "Login required") {
          this.loginWithRedirect();
          return EMPTY;
        }
        return throwError(err);
      })
    );
  }


  private loginWithRedirect() {
    const randomStateValue = window.location.href.replace(window.location.origin, '');
    localStorage.setItem('randomStateValue', randomStateValue);

    this.auth.loginWithRedirect({
      appState: { returnTo: randomStateValue },
    });
  }
}

