export enum EnvironmentEnum {
  DEVELOPMENT = 0,
  LOCAL,
  PRODUCTION,
  TEST
}

export interface IAuth {
  domain: string;
  clientId: string;
  authorizationParams: {
    audience: string;
    redirect_uri: string;
  }
}

export interface IEnvironment {
  auth: IAuth;
  httpInterceptor: {
    allowedList: Array<string>,
  }
  environment: EnvironmentEnum;
  environmentName: string;
  bearerToken: string;
  version: string;

  operatorApiEndpoint: string;
  sourcingServiceEndpoint: string;
  aircraftServiceEndpoint: string,
  testMode: string;
  consoleSiteUrl?: string;
  jetStudioSiteUrl?: string;
}
