import { Injectable } from '@angular/core';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { storeFreeze } from 'ngrx-store-freeze';
import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';

import * as fromCore from '../core/reducers';
import * as fromPublishFlights from '../modules/+publish/application/reducers';
import * as fromFulfillFlights from '../modules/+fulfill/application/reducers';
import * as fromOperatorProfile from '../modules/+profile/application/reducers';
import * as fromFleet from '../modules/+fleet/application/reducers';
import * as fromPricing from '../modules/+pricing/application/reducers'

import { EnvironmentEnum } from '../../environments/environment.interface';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

@Injectable()
export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

export interface State {
  core: fromCore.State;
  publish: fromPublishFlights.State;
  fulfill: fromFulfillFlights.State;
  profile: fromOperatorProfile.State;
  fleet: fromFleet.State;
  pricing: fromPricing.State;
}

export const reducers: ActionReducerMap<State> = {
  core: fromCore.reducer,
  publish: fromPublishFlights.reducer,
  fulfill: fromFulfillFlights.reducer,
  profile: fromOperatorProfile.reducer,
  fleet: fromFleet.reducer,
  pricing: fromPricing.reducer,
};

export const metaReducers: Array<MetaReducer<State>> = environment.environment !== EnvironmentEnum.PRODUCTION ? [storeFreeze] : [];
