export interface IConfigService {
  get(key: ConfigKeysEnum): string;
  load(): Promise<any>;
}

export enum ConfigKeysEnum {
  AUTHORIZATION_TOKEN = 'AuthorizationToken',
  TEST_MODE = 'TestMode',
  OPERATOR_API_ENDPOINT = 'OperatorApiEndpoint',
  SOURCING_SERVICE_ENDPOINT = 'SourcingServiceEndpoint',
  AIRCRAFT_SERVICE_ENDPOINT = 'AircraftServiceEndpoint',
  CONSOLE_SITE_URL = 'ConsoleSiteUrl',
  JET_STUDIO_SITE_URL = 'JetStudioSiteUrl',
}