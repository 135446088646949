import { EnvironmentEnum, IAuth, IEnvironment } from './environment.interface';

const authConfig: IAuth = {
  domain: 'https://auth0.prod.ea.vistajet.com',
  clientId: '42i3cZohfzy8th0hX0wtPWYxnbMn8W9Q',
  authorizationParams: {
    audience: "OperatorPlatform",
    redirect_uri: window.location.origin,
  },
};

const { domain, clientId, authorizationParams } = authConfig;

export const environment: IEnvironment = {
  auth: {
    domain,
    clientId,
    authorizationParams,
  },
  httpInterceptor: {
    allowedList: [`*`],
  },
  bearerToken: null,
  environment: EnvironmentEnum.PRODUCTION,
  environmentName: 'PROD',
  version: '2.1.0',
  testMode: null,

  operatorApiEndpoint: 'https://operator.mp.prod.jetsmarter.io',
  sourcingServiceEndpoint: 'https://sourcing.mp.prod.jetsmarter.io',
  aircraftServiceEndpoint: 'https://aircraft.mp.prod.jetsmarter.io',
  consoleSiteUrl: 'https://console.vista.studio',
  jetStudioSiteUrl: 'https://requests.vista.studio',
};
