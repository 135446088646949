import { Injectable } from '@angular/core';
import * as contracts from './contracts/user';
import * as models from '../../models';
import { of, Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';

@Injectable()
export class UserServiceMock implements contracts.IUserService {

  constructor(private auth: AuthService) {}

  getUser(request: contracts.GetUserRequest): Observable<contracts.GetUserResponse> {

    const user = new models.User();
    user.firstName = 'Test';
    user.lastName = 'Delta';

    const response = new contracts.GetUserResponse();
    response.entity = user;


    return of(response);
  }

  signOut(request: contracts.SignOutRequest): Observable<contracts.SignOutResponse> {

    const response = new contracts.SignOutResponse();

    this.auth.logout({logoutParams: { returnTo: window.location.origin }});
    return of(response);
  }

  logUserActivity(request: models.UserActionsEnum): Observable<contracts.LogUserActivityResponse> {
    const response = new contracts.LogUserActivityResponse();
    return of(response);
  }
}
