import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { IConfigService, ConfigKeysEnum } from './contracts';


@Injectable()
export class ConfigMockService implements IConfigService {
  private config: Object = {};

  get(key: ConfigKeysEnum): string {
    return this.config[key] || '';
  }

  load(): Promise<any> {
    this.config[ConfigKeysEnum.AUTHORIZATION_TOKEN] = environment.bearerToken;
    this.config[ConfigKeysEnum.OPERATOR_API_ENDPOINT] = environment.operatorApiEndpoint;
    this.config[ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT] = environment.sourcingServiceEndpoint;
    this.config[ConfigKeysEnum.AIRCRAFT_SERVICE_ENDPOINT] = environment.aircraftServiceEndpoint;
    this.config[ConfigKeysEnum.TEST_MODE] = environment.testMode;
    this.config[ConfigKeysEnum.JET_STUDIO_SITE_URL] = environment.jetStudioSiteUrl;
    this.config[ConfigKeysEnum.CONSOLE_SITE_URL] = environment.consoleSiteUrl;

    return Promise.resolve();
  }
}
