import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store} from '@ngrx/store';

import * as actions from '../actions/country-list-collection';
import * as sharedTypes from '../types';

import * as contracts from '../services/country-list/contracts';

import { catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class CountryListCollectionEffects {

  load$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.LoadAction>(actions.LOAD),
    switchMap(() => {
      return this.countryListService.getCountries().pipe(
        map((serviceResponse: contracts.GetCountriesResponse) => {
          const loadSuccessPayload = new actions.LoadSuccessPayload();
          loadSuccessPayload.entities = serviceResponse.entities;
          return new actions.LoadSuccessAction(loadSuccessPayload);
        }),
        catchError((err) => {
          const { message } = err;
          this.notificationService.show(
            message,
            sharedTypes.NotificationStyle.Bar,
            sharedTypes.NotificationType.Danger,
            sharedTypes.NotificationPosition.Top,
            0
          );
          return of(new actions.LoadFailAction());
        })
      );
    })
  ));


  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    @Inject(sharedTypes.NOTIFICATION_SERVICE_TOKEN)
    private notificationService: sharedTypes.INotificationService,
    @Inject(contracts.COUNTRY_LIST_SERVICE_TOKEN)
    private countryListService: contracts.ICountryListService
  ) {}
}
