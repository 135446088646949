import { Action } from '@ngrx/store';
import * as models from '../models';

export const LOAD = '[Shared Country List Collection] Load';
export const LOAD_SUCCESS = '[Shared Country List Collection] Load Success';
export const LOAD_FAIL = '[Shared Country List Collection] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessPayload {
  entities: Array<models.Country>;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction;
