import * as actions from '../actions/country-list-collection';
import * as models from '../models';

import * as _ from 'lodash';

export interface State {
  isLoading: boolean;
  isLoaded: boolean;
  countries: Array<models.Country>;
}

const initialState: State = {
  isLoading: false,
  isLoaded: false,
  countries: []
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const countries = _.cloneDeep(action.payload.entities);

      return {
        countries,
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.LOAD_FAIL: {
      return {
        ...state,
        isLoaded: false,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getCountriesCollection = (state: State) => state.countries;
