import { Injectable } from '@angular/core';
import * as contracts from './contracts';
import * as models from '../../models';
import { Observable, of } from 'rxjs';
import { response as getCountriesResponse } from './get-countries-list.response';
import { delay } from 'rxjs/operators';

@Injectable()
export class CountryListService implements contracts.ICountryListService {
  getCountries(): Observable<contracts.GetCountriesResponse> {
    const response = new contracts.GetCountriesResponse();
    response.entities = getCountriesResponse.map(i => {
      const a = new models.Country();
      a.name = i.name;
      a.code = i.code;
      return a;
    });

    return of(response).pipe(delay(500));
  }
}
