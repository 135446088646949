import { InjectionToken } from '@angular/core';
import * as models from '../../models';
import { Observable } from 'rxjs';

export interface ICountryListService {
  getCountries(): Observable<GetCountriesResponse>;
}

export const COUNTRY_LIST_SERVICE_TOKEN = new InjectionToken('resourceMgmt.ICountryListService');

export class GetCountriesResponse {
  entities: Array<models.Country>;
}
