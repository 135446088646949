export const response = [
  {
      name: "Afghanistan",
      code: "AF"
  },
  {
      name: "Albania",
      code: "AL"
  },
  {
      name: "Algeria",
      code: "DZ"
  },
  {   
      name: "American Samoa",
      code: "AS"
  },
  {      
      name: "Angola",
      code: "AO"
  },
  {
      name: "Anguilla Is.",
      code: "AI"
  },
  {
      name: "Antarctica",
      code: "AQ"
  },
  {
      name: "Antigua And Barbuda",
      code: "AG"
  },
  {
      name: "Argentina",
      code: "AR"
  },
  {
      name: "Armenia",
      code: "AM"
  },
  {
      name: "Aruba Is.",
      code: "AW"
  },
  {
      name: "Australia",
      code: "AU"
  },
  {
      name: "Austria",
      code: "AT"
  },
  {
      name: "Azerbaijan",
      code: "AZ"
  },
  {
      name: "Bahamas",
      code: "BS"
  },
  {
      name: "Bahrain",
      code: "BH"
  },
  {
      name: "Bangladesh",
      code: "BD"
  },
  {
      name: "Barbados",
      code: "BB"
  },
  {
      name: "Belarus",
      code: "BY"
  },
  {
      name: "Belgium",
      code: "BE"
  },
  {
      name: "Belize",
      code: "BZ"
  },
  {
      name: "Benin",
      code: "BJ"
  },
  {
      name: "Bermuda",
      code: "BM"
  },
  {
      name: "Bhutan",
      code: "BT"
  },
  {
      name: "Bolivia",
      code: "BO"
  },
  {
      name: "Bonaire St Eustatius Saba",
      code: "BQ"
  },
  {
      name: "Bosnia And Herzegovina",
      code: "BA"
  },
  {
      name: "Botswana",
      code: "BW"
  },
  {
      name: "Brazil",
      code: "BR"
  },
  {
      name: "Br Indian Ocean Territory",
      code: "IO"
  },
  {
      name: "British Virgin Islands",
      code: "VG"
  },
  {
      name: "Brunei Darussalam",
      code: "BN"
  },
  {
      name: "Bulgaria",
      code: "BG"
  },
  {
      name: "Burkina Faso",
      code: "BF"
  },
  {
      name: "Burundi",
      code: "BI"
  },
  {
      name: "Cambodia",
      code: "KH"
  },
  {
      name: "Cameroon",
      code: "CM"
  },
  {
      name: "Canada",
      code: "CA"
  },
  {
      name: "Cape Verde",
      code: "CV"
  },
  {
      name: "Cayman Is.",
      code: "KY"
  },
  {
      name: "Central African Republic",
      code: "CF"
  },
  {
      name: "Chad",
      code: "TD"
  },
  {
      name: "Chile",
      code: "CL"
  },
  {
      name: "China",
      code: "CN"
  },
  {
      name: "Christmas Is.",
      code: "CX"
  },
  {
      name: "Cocos (Keeling) Is.",
      code: "CC"
  },
  {
      name: "Colombia",
      code: "CO"
  },
  {
      name: "Comoros Is.",
      code: "KM"
  },
  {
      name: "Congo",
      code: "CG"
  },
  {
      name: "Cook Is.",
      code: "CK"
  },
  {
      name: "Costa Rica",
      code: "CR"
  },
  {
      name: "Cote d'Ivoire",
      code: "CI"
  },
  {
      name: "Croatia",
      code: "HR"
  },
  {
      name: "Cuba",
      code: "CU"
  },
  {
      name: "Curacao",
      code: "CW"
  },
  {
      name: "Cyprus",
      code: "CY"
  },
  {
      name: "Czech Republic",
      code: "CZ"
  },
  {
      name: "Denmark",
      code: "DK"
  },
  {
      name: "Djibouti",
      code: "DJ"
  },
  {
      name: "Dominica",
      code: "DM"
  },
  {
      name: "Dominican Republic",
      code: "DO"
  },
  {
      name: "Ecuador",
      code: "EC"
  },
  {
      name: "Egypt",
      code: "EG"
  },
  {
      name: "El Salvador",
      code: "SV"
  },
  {
      name: "Equatorial Guinea",
      code: "GQ"
  },
  {
      name: "Eritrea",
      code: "ER"
  },
  {
      name: "Estonia",
      code: "EE"
  },
  {
      name: "Ethiopia",
      code: "ET"
  },
  {
      name: "Falkland Is.",
      code: "FK"
  },
  {
      name: "Faroe Islands",
      code: "FO"
  },
  {
      name: "Fiji",
      code: "FJ"
  },
  {
      name: "Finland",
      code: "FI"
  },
  {
      name: "France",
      code: "FR"
  },
  {
      name: "French Guiana",
      code: "GF"
  },
  {
      name: "French Polynesia",
      code: "PF"
  },
  {
      name: "Gabon",
      code: "GA"
  },
  {
      name: "Gambia",
      code: "GM"
  },
  {
      name: "Georgia",
      code: "GE"
  },
  {
      name: "Germany",
      code: "DE"
  },
  {
      name: "Ghana",
      code: "GH"
  },
  {
      name: "Gibraltar",
      code: "GI"
  },
  {
      name: "Greece",
      code: "GR"
  },
  {
      name: "Greenland",
      code: "GL"
  },
  {
      name: "Grenada",
      code: "GD"
  },
  {
      name: "Guadeloupe",
      code: "GP"
  },
  {
      name: "Guam",
      code: "GU"
  },
  {
      name: "Guatemala",
      code: "GT"
  },
  {
      name: "Guernsey",
      code: "GG"
  },
  {
      name: "Guinea",
      code: "GN"
  },
  {
      name: "Guinea-Bissau",
      code: "GW"
  },
  {
      name: "Guyana",
      code: "GY"
  },
  {
      name: "Haiti",
      code: "HT"
  },
  {
      name: "Honduras",
      code: "HN"
  },
  {
      name: "Hong Kong",
      code: "HK"
  },
  {
      name: "Hungary",
      code: "HU"
  },
  {
      name: "Iceland",
      code: "IS"
  },
  {
      name: "India",
      code: "IN"
  },
  {
      name: "Indonesia",
      code: "ID"
  },
  {
      name: "Iran",
      code: "IR"
  },
  {
      name: "Iraq",
      code: "IQ"
  },
  {
      name: "Ireland",
      code: "IE"
  },
  {
      name: "Isle Of Man",
      code: "IM"
  },
  {
      name: "Israel",
      code: "IL"
  },
  {
      name: "Italy",
      code: "IT"
  },
  {
      name: "Jamaica",
      code: "JM"
  },
  {
      name: "Japan",
      code: "JP"
  },
  {
      name: "Jersey",
      code: "JE"
  },
  {
      name: "Jordan",
      code: "JO"
  },
  {
      name: "Kazakhstan",
      code: "KZ"
  },
  {
      name: "Kenya",
      code: "KE"
  },
  {
      name: "Kiribati",
      code: "KI"
  },
  {
      name: "Korea",
      code: "KR"
  },
  {
      name: "Kuwait",
      code: "KW"
  },
  {
      name: "Kyrgyzstan",
      code: "KG"
  },
  {
      name: "Lao People's Dem. Rep.",
      code: "LA"
  },
  {
      name: "Latvia",
      code: "LV"
  },
  {
      name: "Lebanon",
      code: "LB"
  },
  {
      name: "Lesotho",
      code: "LS"
  },
  {
      name: "Liberia",
      code: "LR"
  },
  {
      name: "Libya",
      code: "LY"
  },
  {
      name: "Lithuania",
      code: "LT"
  },
  {
      name: "Luxembourg",
      code: "LU"
  },
  {
      name: "Macao",
      code: "MO"
  },
  {
      name: "Macedonia",
      code: "MK"
  },
  {
      name: "Madagascar",
      code: "MG"
  },
  {
      name: "Malawi",
      code: "MW"
  },
  {
      name: "Malaysia",
      code: "MY"
  },
  {
      name: "Maldives",
      code: "MV"
  },
  {
      name: "Mali",
      code: "ML"
  },
  {
      name: "Malta",
      code: "MT"
  },
  {
      name: "Marshall Is.",
      code: "MH"
  },
  {
      name: "Martinique Is.",
      code: "MQ"
  },
  {
      name: "Mauritania",
      code: "MR"
  },
  {
      name: "Mauritius Is.",
      code: "MU"
  },
  {
      name: "Mayotte Is.",
      code: "YT"
  },
  {
      name: "Mexico",
      code: "MX"
  },
  {
      name: "Micronesia",
      code: "FM"
  },
  {
      name: "Moldova",
      code: "MD"
  },
  {
      name: "Monaco",
      code: "MC"
  },
  {
      name: "Mongolia",
      code: "MN"
  },
  {
      name: "Montenegro",
      code: "ME"
  },
  {
      name: "Montserrat Is.",
      code: "MS"
  },
  {
      name: "Morocco",
      code: "MA"
  },
  {
      name: "Mozambique",
      code: "MZ"
  },
  {
      name: "Myanmar",
      code: "MM"
  },
  {
      name: "Namibia",
      code: "NA"
  },
  {
      name: "Nauru",
      code: "NR"
  },
  {
      name: "Nepal",
      code: "NP"
  },
  {
      name: "Netherlands",
      code: "NL"
  },
  {
      name: "New Caledonia Is.",
      code: "NC"
  },
  {
      name: "New Zealand",
      code: "NZ"
  },
  {
      name: "Nicaragua",
      code: "NI"
  },
  {
      name: "Niger",
      code: "NE"
  },
  {
      name: "Nigeria",
      code: "NG"
  },
  {
      name: "Niue",
      code: "NU"
  },
  {
      name: "Norfolk Is.",
      code: "NF"
  },
  {
      name: "Northern Cyprus",
      code: null
  },
  {
      name: "Northern Mariana Is.",
      code: "MP"
  },
  {
      name: "Norway",
      code: "NO"
  },
  {
      name: "Oman",
      code: "OM"
  },
  {
      name: "Pakistan",
      code: "PK"
  },
  {
      name: "Palau",
      code: "PW"
  },
  {
      name: "Panama",
      code: "PA"
  },
  {
      name: "Papua New Guinea",
      code: "PG"
  },
  {
      name: "Paracel Is.",
      code: "PAR"
  },
  {
      name: "Paraguay",
      code: "PY"
  },
  {
      name: "Peru",
      code: "PE"
  },
  {
      name: "Philippines",
      code: "PH"
  },
  {
      name: "Poland",
      code: "PL"
  },
  {
      name: "Portugal",
      code: "PT"
  },
  {
      name: "Puerto Rico",
      code: "PR"
  },
  {
      name: "Qatar",
      code: "QA"
  },
  {
      name: "Reunion Is.",
      code: "RE"
  },
  {
      name: "Romania",
      code: "RO"
  },
  {
      name: "Russian Federation",
      code: "RU"
  },
  {
      name: "Rwanda",
      code: "RW"
  },
  {
      name: "Saint Helena",
      code: "SH"
  },
  {
      name: "Saint Kitts And Nevis",
      code: "KN"
  },
  {
      name: "Saint Lucia",
      code: "LC"
  },
  {
      name: "Saint Martin",
      code: "MF"
  },
  {
      name: "Samoa",
      code: "WS"
  },
  {
      name: "San Marino",
      code: "SM"
  },
  {
      name: "Sao Tome & Principe",
      code: "ST"
  },
  {
      name: "Saudi Arabia",
      code: "SA"
  },
  {
      name: "Senegal",
      code: "SN"
  },
  {
      name: "Serbia",
      code: "RS"
  },
  {
      name: "Seychelles",
      code: "SC"
  },
  {
      name: "Sierra Leone",
      code: "SL"
  },
  {
      name: "Singapore",
      code: "SG"
  },
  {
      name: "Sint Maarten",
      code: "SX"
  },
  {
      name: "Slovak Republic",
      code: "SK"
  },
  {
      name: "Slovenia",
      code: "SI"
  },
  {
      name: "Solomon Islands",
      code: "SB"
  },
  {
      name: "Somalia",
      code: "SO"
  },
  {
      name: "South Africa",
      code: "ZA"
  },
  {
      name: "South Sudan",
      code: "SS"
  },
  {
      name: "Spain",
      code: "ES"
  },
  {
      name: "Spratly Is.",
      code: "SPR"
  },
  {
      name: "Sri Lanka",
      code: "LK"
  },
  {
      name: "St Barthelemy",
      code: "BL"
  },
  {
      name: "St Pierre & Miquelon",
      code: "PM"
  },
  {
      name: "St. Vincent & Grenadines",
      code: "VC"
  },
  {
      name: "Sudan",
      code: "SD"
  },
  {
      name: "Suriname",
      code: "SR"
  },
  {
      name: "Svalbard & Jan Mayen Is.",
      code: "SJ"
  },
  {
      name: "Swaziland",
      code: "SZ"
  },
  {
      name: "Sweden",
      code: "SE"
  },
  {
      name: "Switzerland",
      code: "CH"
  },
  {
      name: "Syrian Arab Republic",
      code: "SY"
  },
  {
      name: "Taiwan",
      code: "TW"
  },
  {
      name: "Tajikistan",
      code: "TJ"
  },
  {
      name: "Tanzania",
      code: "TZ"
  },
  {
      name: "Thailand",
      code: "TH"
  },
  {
      name: "Timor-Leste",
      code: "TL"
  },
  {
      name: "Togo",
      code: "TG"
  },
  {
      name: "Tonga",
      code: "TO"
  },
  {
      name: "Trinidad & Tobago",
      code: "TT"
  },
  {
      name: "Tunisia",
      code: "TN"
  },
  {
      name: "Turkiye",
      code: "TR"
  },
  {
      name: "Turkmenistan",
      code: "TM"
  },
  {
      name: "Turks & Caicos Islands",
      code: "TC"
  },
  {
      name: "Tuvalu",
      code: "TV"
  },
  {
      name: "Uganda",
      code: "UG"
  },
  {
      name: "Ukraine",
      code: "UA"
  },
  {
      name: "United Arab Emirates",
      code: "AE"
  },
  {
      name: "United Kingdom",
      code: "GB"
  },
  {
      name: "United States",
      code: "US"
  },
  {
      name: "Uruguay",
      code: "UY"
  },
  {
      name: "U.S. Minor Outlying Is.",
      code: "UM"
  },
  {
      name: "U.S. Virgin Is.",
      code: "VI"
  },
  {
      name: "Uzbekistan",
      code: "UZ"
  },
  {
      name: "Vanuatu",
      code: "VU"
  },
  {
      name: "Venezuela",
      code: "VE"
  },
  {
      name: "Vietnam",
      code: "VN"
  },
  {
      name: "Wallis And Futuna Is.",
      code: "WF"
  },
  {
      name: "Western Sahara",
      code: "EH"
  },
  {
      name: "Yemen",
      code: "YE"
  },
  {
      name: "Zambia",
      code: "ZM"
  },
  {
      name: "Zimbabwe",
      code: "ZW"
  }
];
